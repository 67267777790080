import { useState } from "react"
import { settings } from "../config/globalSettings"

interface Props {
    isLoged: boolean,
}

export const IconLogin = (props: Props) => {
    const { isLoged } = props;

    return <svg xmlns="http://www.w3.org/2000/svg" width="60"  viewBox="0 0 500 500">
        <g fill={settings.iconColor}>
            <path d="M 421.232,0 H 250.768 L 248,6.32 245.232,0 H 74.768 L 15.736,134.944 73.168,496 H 246.824 L 248,488.632 249.168,496 H 422.824 L 480.264,134.944 Z M 233.168,480 H 216 V 464 H 200 V 480 H 184 V 464 H 168 V 480 H 152 V 464 H 136 V 480 H 120 V 464 H 104 V 480 H 86.832 L 32.272,137.056 85.232,16 H 104 V 32 H 120 V 16 H 136 V 32 H 152 V 16 H 168 V 32 H 184 V 16 H 200 V 32 H 216 V 16 H 234.768 L 287.736,137.056 Z M 303.649,134.204 274.968,67.96 284.944,48 H 387.056 L 431.712,137.312 385.112,448 H 286.888 L 271.152,343.072 Z M 409.168,480 H 262.832 L 256.104,437.72 262.832,395.44 273.112,464 H 398.896 L 448.296,134.688 396.944,32 H 275.056 L 266.616,48.88 256.736,26.288 261.232,16 H 410.76 L 463.728,137.056 Z" />
			<path d="M184,136 V80 h-48 v56H72 v48 h64 v232 h48V184h64v-48H184z M232,168h-64v232h-16V168H88v-16h64V96h16v56h64V168z"/>

            { isLoged && <path d="M 381.176,144.52 398.024,125.264 385.976,114.736 383.68,117.36 C 383.88,115.592 384,113.816 384,112 384,85.528 362.472,64 336,64 313.368,64 294.4,79.76 289.36,100.864 L 274.968,67.96 284.944,48 H 387.056 L 431.712,137.312 416,242.056 V 184 C 416,163.72 400.768,147.088 381.176,144.52 Z M 288,288 V 432 H 384 V 288 H 409.112 L 385.112,448 H 286.888 L 271.152,343.072 279.92,288 Z M 282.464,272 288,237.192 V 272 Z M 336,144 C 318.352,144 304,129.648 304,112 304,94.352 318.352,80 336,80 353.648,80 368,94.352 368,112 368,129.648 353.648,144 336,144 Z M 346.368,160 336.152,171.68 326.416,160 Z M 305.584,160 335.856,196.32 367.632,160 H 376 C 389.232,160 400,170.768 400,184 V 272 H 384 V 184 H 368 V 248 H 304 V 184 H 296.464 L 300.28,160 Z M 328,280 V 416 H 304 V 264 H 368 V 416 H 344 V 280 Z" /> }
            {/* <path d="M 336,144 C 318.352,144 304,129.648 304,112 304,94.352 318.352,80 336,80 353.648,80 368,94.352 368,112 368,129.648 353.648,144 336,144 Z M 346.368,160 336.152,171.68 326.416,160 Z M 305.584,160 335.856,196.32 367.632,160 H 376 C 389.232,160 400,170.768 400,184 V 272 H 384 V 184 H 368 V 248 H 304 V 184 H 296.464 L 300.28,160 Z M 328,280 V 416 H 304 V 264 H 368 V 416 H 344 V 280 Z" /> */}
		</g>
    </svg>
}